<template>
  <div>
    <router-link class="come-back" to="/admin/section">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">
      {{ isEdit ? `Редактирование секции №${$route.params.id}` : 'Создание секции' }}
    </h2>
    <tricolor-line class="card_containet__tricolor"/>

    <form @submit.prevent="createSection" ref="createSection">
        <div class="content-sb">
          <p>Заголовок<span>*</span></p>
          <input required placeholder="Введите заголовок" v-model="sectionData.title" type="text" name="title" class="input blue">
        </div>

        <div class="content-sb">
          <p>Заголовок (Англ.)</p>
          <input placeholder="Введите заголовок (Англ.)" v-model="sectionData.title_eng" type="text" name="title_eng" class="input blue">
        </div>

        <div class="content-sb">
          <p>Приоритет<span>*</span></p>
          <input required v-model="sectionData.order" type="number" name="order" class="input blue">
        </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/section" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createSection',

  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      sectionData: {
        title: null,
        title_eng: '',
        order: 1
      }
    }
  },

  watch: {
    '$store.getters.getDetailedSection' () {
      const dataFromServer = this.$store.getters.getDetailedSection

      this.sectionData = {
        title: dataFromServer.title,
        title_eng: dataFromServer.title_eng ?? '',
        order: dataFromServer.order
      }
      this.$forceUpdate()
    }
  },

  created () {
    if (this.isEdit) {
      this.$store.dispatch('setDetailedSection', this.$route.params.id)
    }
  },

  methods: {
    createSection () {
      const formData = new FormData()
      formData.append('title', this.sectionData.title)
      formData.append('title_eng', this.sectionData.title_eng)
      formData.append('order', this.sectionData.order)

      if (!this.isEdit) {
        this.$store.dispatch('createSection', formData)
      } else {
        formData.append('_method', 'PUT')
        this.$store.dispatch('editSection', {
          data: formData,
          id: this.$route.params.id
        })
      }
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
